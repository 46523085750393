/**
 * Get an object of needed ad and content data
 * @param {*} ad IMA Ad Object
 * @param {*} vertical
 * @param {*} adStartTime
 * @param {*} url
 * @param {*} currentPlayingCatalog
 * @param {*} duration
 * @param {*} currentTrack
 * @param {*} currentAdSettings
 * @returns
 */
export const getAdAndContentParams = (
    ad,
    vertical,
    adStartTime,
    url,
    currentPlayingCatalog,
    duration,
    currentTrack,
    currentAdSettings
) => {
    const adId = ad.getAdId()
    const adDuration = ad.getDuration()
    const isRadio = vertical === 'radio'
    const playTime = Date.now()
    const diff = playTime - adStartTime
    const adLoadTime = diff / 1000
    const {
        source: catalogSource,
        title: catalogTitle,
        id: catalogId,
    } = currentPlayingCatalog
    const contentId = isRadio ? currentPlayingCatalog.id : currentTrack?.id
    const contentTitle = isRadio
        ? currentPlayingCatalog.title
        : currentTrack?.title
    const adType = currentAdSettings.prerollEnabled ? 'pre roll' : 'mid roll'
    const adMediaType = 'audio'
    return {
        adId,
        adDuration,
        adLoadTime,
        url,
        catalogId,
        catalogSource,
        catalogTitle,
        duration,
        contentId,
        contentTitle,
        currentVertical: vertical,
        adType,
        adMediaType,
    }
}
