import {
    UPDATE_TAB,
    UPDATE_NAV,
    SEGMENT_LIST,
    UPDATE_OFFSET,
    UPDATE_SEGMENT_STATUS,
    GET_BANNER,
    UPDATE_BANNER_STATUS,
    GET_SEGMENT_DETAIL,
    UPDATE_SEGMENT_DETAIL_STATUS,
    RESET_SEGMENT_LIST,
    UPDATE_RADIO_IMAGE_LOADED,
    UPDATE_ELAPSED_TIME,
} from '@/store/types/home'

export const updateTab = (payload) => ({
    type: UPDATE_TAB,
    payload,
})
export const updateNav = (payload) => ({
    type: UPDATE_NAV,
    payload,
})

export const segmentList = (payload) => ({
    type: SEGMENT_LIST,
    payload,
})

export const updateOffset = (payload) => ({
    type: UPDATE_OFFSET,
    payload,
})

export const updateSegmentStatus = (payload) => ({
    type: UPDATE_SEGMENT_STATUS,
    payload,
})

export const getBanner = (payload) => ({
    type: GET_BANNER,
    payload,
})

export const updateBannerStatus = (payload) => ({
    type: UPDATE_BANNER_STATUS,
    payload,
})

export const getSegmentDetail = (payload) => ({
    type: GET_SEGMENT_DETAIL,
    payload,
})

export const updateSegmentDetailStatus = (payload) => ({
    type: UPDATE_SEGMENT_DETAIL_STATUS,
    payload,
})

export const resetSegmentList = () => ({
    type: RESET_SEGMENT_LIST,
})

export const updateRadioImageLoaded = (payload) => ({
    type: UPDATE_RADIO_IMAGE_LOADED,
    payload,
})

export const updateElapsedTime = (payload) => ({
    type: UPDATE_ELAPSED_TIME,
    payload,
})
