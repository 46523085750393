/**
 * GET Remaining time of an audio content
 * @param {number} currentPosition Current position of the audio playback
 * @param {number} duration Total duration of the audio
 * @return {string} Duration left
 */

export const getLeftDurationString = (currentPosition, duration) => {
    const leftDurationInMinutes = Math.floor((duration - currentPosition) / 60)
    const leftDurationInMinutesInString =
        leftDurationInMinutes < 10
            ? `0${leftDurationInMinutes}`
            : `${leftDurationInMinutes}`
    const leftDurationInSeconds = Math.round(duration - currentPosition) % 60
    const leftDurationInSecondsInString =
        leftDurationInSeconds < 10
            ? `0${leftDurationInSeconds}`
            : `${leftDurationInSeconds}`

    return `${leftDurationInMinutesInString}:${leftDurationInSecondsInString}`
}
