export const getTTagString = (content, currentPlayingCatalog) => {
    const vertical = content?.catalog
        ? content?.catalog?.type
        : currentPlayingCatalog?.type
    const source = content?.source
        ? content?.source
        : currentPlayingCatalog?.source
    const catalogTitle = content?.catalog
        ? content?.catalog?.title
        : currentPlayingCatalog?.title
    const genres = content?.genres
        ? content?.genres?.map((genre) => genre.name)
        : currentPlayingCatalog.genres?.map((genre) => genre.name)
    const tags = content?.tags?.map((tag) => tag.title)

    const ttagString = `c_vertical:${vertical},c_source:${source},c_catalog:${catalogTitle}${
        genres ? ',c_genre=' + genres.join(',') : ''
    }${tags ? ',c_tag=' + tags.join(',') : ''}`

    return ttagString
}
