export const UnMuteTooltip = () => {
    return (
        <div
            style={{
                position: 'absolute',
                top:'10px',
                right:'10px',
                color: 'white',
                zIndex: 100,
                padding:'5px 16px',
                borderRadius:'14px',
                background: "#00000080",
                display:'flex',
                justifyContent:'center',
                alignItems:"center",
                cursor: 'pointer',
            }}
        >
            <span>
                <img style={{zIndex:1000}} src="/img/muted_white.svg" />
            </span>
            <span>Bunyikan suara</span>
        </div>
    )
}
