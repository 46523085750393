const NumberConversion = (num) => {
    if (!num) return 0

    num = num.toString().replace(/[^0-9.]/g, '')
    if (num < 1000) {
        return num
    }
    let si = [
        { v: 1e3, s: 'rb' },
        { v: 1e6, s: 'jt' },
        { v: 1e9, s: 'm' },
        { v: 1e12, s: 't' },
    ]
    let index
    for (index = si.length - 1; index > 0; index--) {
        if (num >= si[index].v) {
            break
        }
    }
    return (
        (num / si[index].v)
            .toFixed(2)
            .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + ` ${si[index].s}`
    )
}
export default NumberConversion
