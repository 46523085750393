import { getAdAndContentParams } from './getAdAndContentParams'

/**
 * Gets Amplitude or Firebase event params for Ads
 * @param {*} ad
 * @param {*} vertical
 * @param {*} adStartTime
 * @param {*} adUrl
 * @param {*} currentPlayingCatalog
 * @param {*} contentDuration
 * @param {*} currentTrack
 * @param {*} currentAdSettings
 * @param {*} platform
 * @returns
 */
export const getAdTrackingEventParams = (
    ad,
    vertical,
    adStartTime,
    adUrl,
    currentPlayingCatalog,
    contentDuration,
    currentTrack,
    currentAdSettings,
    platform = 'amplitude'
) => {
    const {
        adId,
        adDuration,
        adLoadTime,
        url,
        catalogId,
        catalogSource,
        catalogTitle,
        duration,
        contentId,
        contentTitle,
        currentVertical,
        adType,
        adMediaType,
    } = getAdAndContentParams(
        ad,
        vertical,
        adStartTime,
        adUrl,
        currentPlayingCatalog,
        contentDuration,
        currentTrack,
        currentAdSettings
    )

    if (platform === 'amplitude') {
        return {
            'ad id': adId,
            'ad duration': adDuration,
            'ad load time': adLoadTime,
            'ad url': url,
            'catalog id': catalogId,
            'catalog source': catalogSource,
            'catalog title': catalogTitle,
            'content duration': duration,
            'content id': contentId,
            'content title': contentTitle,
            vertical: currentVertical,
            'ad type': adType,
            'ad media type': adMediaType,
        }
    } else if (platform === 'firebase') {
        return {
            adId,
            vertical: currentVertical,
            adLoadTime,
            adDuration,
            adUrl: url,
            catalogSource,
            catalogTitle,
            contentDuration: duration,
            contentId,
            contentTitle,
            adType,
            catalogId,
            adMediaType,
        }
    }

    return {}
}
