import { IconButton } from './styles'
import { useCatalogSubscribed } from '@/helpers/useCatalogSubscribed'
import { setShowLoginPrompt } from '@/store/actions/player'
import { subscribeToCatalog } from '@/utils/api-ssr'
import { Dropdown, notification } from 'antd'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

export const ThreeDotMenu = ({ currentCatalog, currentContent, isRadio }) => {
    const [api, contextHolder] = notification.useNotification()
    const router = useRouter()
    const { data: session } = useSession()
    const token = session?.token
    const userId = session?.userInfo?.id
    const isLoggedin = session?.isLoggedin
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const [open, setOpen] = useState(false)

    const hide = () => {
        setOpen(false)
    }

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen)
    }

    const showToast = (message) => {
        api.info({
            message: message,
            placement: 'top',
            duration: 3,
            role: 'status',
            icon: '',
            closeIcon: '',
        })
    }

    const { subscribed, mutate } = useCatalogSubscribed(currentCatalog?.id)

    if (!currentContent) return null

    const items = [
        {
            key: '1',
            label: (
                <a
                    className="player-menu-content"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()

                        router.push(`/catalog/${currentCatalog?.id}`)
                        hide()
                    }}
                >
                    <img src="/img/pod_grey.svg" alt="podcast icon" />
                    <span>Lihat {currentCatalog?.title}</span>
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a
                    className="player-menu-content"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()

                        router.push(`/content/${currentContent?.id}`)
                        hide()
                    }}
                >
                    <img src="/img/pod_grey.svg" alt="podcast icon" />
                    <span>Lihat Deskripsi Episode</span>
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a
                    className="player-menu-content"
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()

                        const episodeLink = `${process.env.NEXT_PUBLIC_SITE_URL}/content/${currentContent?.id}`
                        navigator.clipboard.writeText(episodeLink)

                        showToast('Link Tersalin')
                        hide()
                    }}
                >
                    <img src="/img/share_grey.svg" alt="share icon" />
                    <span>Bagikan</span>
                </a>
            ),
        },
        {
            key: '4',
            label: (
                <a
                    className="player-menu-content"
                    onClick={async (e) => {
                        e.stopPropagation()
                        e.preventDefault()

                        if (!isLoggedin) {
                            dispatch(setShowLoginPrompt(true))
                            return
                        }

                        const catalogId = currentCatalog?.id

                        setLoading(true)

                        await subscribeToCatalog({
                            catalogId,
                            userId,
                            token,
                            subscribed,
                        })

                        await mutate()

                        setLoading(false)
                        hide()
                    }}
                >
                    {loading ? (
                        <Spinner size="sm" />
                    ) : (
                        <img
                            src={
                                subscribed
                                    ? '/img/cross_red_outline.svg'
                                    : '/img/subscribe_to_catalog.svg'
                            }
                            alt="share icon"
                        />
                    )}
                    <span style={{ color: subscribed ? '#FF9292' : 'inherit' }}>
                        {subscribed
                            ? `Unsubscribe ${currentCatalog?.title}`
                            : `Subscribe ${currentCatalog?.title}`}
                    </span>
                </a>
            ),
        },
    ]

    const radioItems = [
        {
            key: '3',
            label: (
                <a
                    className="player-menu-content"
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()

                        const episodeLink = `${process.env.NEXT_PUBLIC_SITE_URL}/catalog/${currentCatalog?.id}`
                        navigator.clipboard.writeText(episodeLink)

                        showToast('Link Tersalin')
                        hide()
                    }}
                >
                    <img src="/img/share_grey.svg" alt="share icon" />
                    <span>Bagikan</span>
                </a>
            ),
        },
    ]

    return (
        <>
            <Dropdown
                open={open}
                onOpenChange={handleOpenChange}
                menu={{
                    items: isRadio ? radioItems : items,
                }}
                trigger={['click']}
            >
                <IconButton
                    onClick={(e) => e.stopPropagation()}
                    style={{ minWidth: '32px' }}
                >
                    <img alt="three dot menu" src="/img/three-dot-menu.svg" />
                </IconButton>
            </Dropdown>

            {contextHolder}
        </>
    )
}
