import { getLeftDurationString } from './getLeftDurationString'
import { getPlayedDurationString } from './getPlayedDurationString'

/**
 * Calculates and sets remaining ad duration
 * @param {*} adEvent IMA AdEvent
 * @param {*} adsManager ima ads manager
 * @param {*} adDurationData ad duration state
 * @param {*} setAdDurationData ad duration state setter
 * @returns the id of the timer so that we can use this id with clearInterval()
 * to cancel the timer.
 */
export const handleAdDuration = (
    adEvent,
    adsManager,
    adDurationData,
    setAdDurationData
) => {
    const ad = adEvent.getAd()
    const adDuration = ad.getDuration()

    return setInterval(() => {
        const totalPlayedSeconds =
            adDuration - parseInt(adsManager.getRemainingTime())

        setAdDurationData({
            ...adDurationData,
            playedSeconds: (totalPlayedSeconds * 100) / adDuration / 100,
            playedDurationInString: getPlayedDurationString(totalPlayedSeconds),
            leftDurationInString: getLeftDurationString(
                totalPlayedSeconds,
                adDuration
            ),
        })
    }, 300)
}
