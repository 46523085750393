import { TooltipContainer } from './Tooltip'
import { IconButton } from './styles'
import { Dropdown } from 'antd'
import { useState } from 'react'

const rates = [1, 1.25, 1.5, 2]

export const PlaybackRateMenu = ({
    playbackRate,
    setPlaybackRate,
    isMobile,
}) => {
    const [open, setOpen] = useState(false)

    const hide = () => {
        setOpen(false)
    }

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen)
    }

    return (
        <>
            <Dropdown
                open={open}
                onOpenChange={handleOpenChange}
                overlayStyle={{ zIndex: 2000 }}
                trigger={['click']}
                menu={{
                    items: rates.map((rate, index) => {
                        return {
                            key: index,
                            label: (
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '3px',
                                        marginLeft: '-20px',
                                        width: '100px',
                                        alignItems: 'center',
                                    }}
                                    className="player-menu-content"
                                    rel="noopener noreferrer"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setPlaybackRate(rate)
                                        hide()
                                    }}
                                >
                                    <img
                                        style={{
                                            visibility:
                                                playbackRate === rate
                                                    ? 'visible'
                                                    : 'hidden',
                                        }}
                                        src="/img/checked_yellow.svg"
                                        alt="podcast icon"
                                        width="25px"
                                        height="25px"
                                    />

                                    <span
                                        style={{
                                            color: '#B9B9B9',
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            lineHeight: '17.5px',
                                            letterSpacing: '0.1px',
                                        }}
                                    >
                                        {rate}x
                                    </span>
                                </div>
                            ),
                        }
                    }),
                }}
            >
                <IconButton
                    onClick={(e) => e.stopPropagation()}
                    style={{
                        minWidth: '32px',
                        color: isMobile ? 'white' : '#bbbbbb',
                        // fontSize: isMobile ? '16px' : '14px',
                        fontSize: '12px',
                        fontWeight: 600,
                        lineHeight: '13.75px',
                        letterSpacing: '0.1px',
                    }}
                >
                    <TooltipContainer title="Kecepatan Putar">
                        {playbackRate}x
                    </TooltipContainer>
                </IconButton>
            </Dropdown>
        </>
    )
}
