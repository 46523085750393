import {
    GET_COLLECTION_DETAIL,
    RECENT_PLAYED_LIST,
    RESET_COLLECTION_DETAIL,
    RESET_RECENT_PLAYED_LIST,
    UPDATE_COLLECTION_ELAPSED_TIME,
    UPDATE_RECENT_PLAYED_STATUS,
} from '../types/collection'

export const recentPlayedList = (payload) => ({
    type: RECENT_PLAYED_LIST,
    payload,
})

export const updateRecentPlayedStatus = (payload) => ({
    type: UPDATE_RECENT_PLAYED_STATUS,
    payload,
})

export const resetRecentPlaylistList = () => ({
    type: RESET_RECENT_PLAYED_LIST,
})

export const resetCollectionDetail = () => ({
    type: RESET_COLLECTION_DETAIL,
})

export const getCollectionDetail = (payload) => ({
    type: GET_COLLECTION_DETAIL,
    payload,
})

export const updateCollectionElapsedTime = (payload) => ({
    type: UPDATE_COLLECTION_ELAPSED_TIME,
    payload,
})
