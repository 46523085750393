/**
 * GET Played time of an audio content
 * @param {number} duration Total duration of the audio
 * @return {string} Duration played
 */

export const getPlayedDurationString = (duration) => {
    const playedTimeInMinutes = Math.floor(duration / 60)
    const playedTimeInMinutesInString =
        playedTimeInMinutes < 10
            ? `0${playedTimeInMinutes}`
            : `${playedTimeInMinutes}`
    const playedTimeInSeconds = Math.round(duration) % 60

    const playedTimeInSecondsInString =
        playedTimeInSeconds < 10
            ? `0${playedTimeInSeconds}`
            : `${playedTimeInSeconds}`

    return `${playedTimeInMinutesInString}:${playedTimeInSecondsInString}`
}
