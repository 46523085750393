/**
 * Continues content playback after ad has finished or ad play faced and error
 * @param {*} event AdEvent
 * @param {*} dispatch redux dispatch function
 * @param {*} initializeCompanionAds redux store action
 * @param {*} initializeAds redux store action
 * @param {*} setPlaying state setter
 * @param {*} adDurationCountInterval interval that runs to calculate the ads remaining duration
 * @param {*} isError optional error flag
 */
export const resumeContentAfterAd = (
    event,
    dispatch,
    initializeCompanionAds,
    initializeAds,
    setPlaying,
    adDurationCountInterval = null,
    isError = false,
    setUserEligibleForAds
) => {
    dispatch(
        initializeCompanionAds({
            miniPlayer: { thumb: null, link: null },
            player: { thumb: null, link: null },
        })
    )

    dispatch(setPlaying(true))

    dispatch(
        initializeAds({
            adPlaying: false,
            showingAd: false,
            adError: isError,
        })
    )

    setUserEligibleForAds(false)

    if (adDurationCountInterval) {
        clearInterval(adDurationCountInterval)
    }
}
